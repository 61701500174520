import L, { LeafletEvent } from 'leaflet';
import { useEffect, useState } from 'react';
import { Popup } from 'react-leaflet';
import { useAppContext } from '../../../../AppContext';
import OCTMap from '../../../component/oct-map/OCTMap';
import { cmpNormalizeString } from '../../../utils/FunctionUtils';
import { useInitContext } from '../../../utils/InitContext';
import AreaBar from '../AreaBar';
import { CAP_USER, configMapByHinhThuc, NAME_COMMUNE, NAME_DISTRICT, NAME_PROVINCE, NAME_RES_COMMUNE, NAME_RES_DISTRICT, NAME_RES_PROVINCE } from '../config';
import useLoadGeoJson from '../../../../hook/useLoadGeoJson';

function BanDoGamSat(props: any) {

  const { userData } = useAppContext()
  const { data, address, setAddress } = useInitContext()
  const [centerMap, setCenterMap] = useState<any>([21.0285, 105.8542])
  const initConfig = configMapByHinhThuc[userData?.phanLoaiCoSo]
  const [configMap, setConfigMap] = useState(initConfig)
  const [geoJsonData, setGeoJsonData] = useState<any>([]);
	const [ProvinceGeoJson, setProvinceJeoJson] = useState({})
  const [DistrictGeoJson, setDistrictJeoJson] = useState({})
	const [CommuneGeoJson, setCommuneJeoJson] = useState({})

  useLoadGeoJson(setProvinceJeoJson, setDistrictJeoJson, setCommuneJeoJson)

  const getGeoJsonData = (nameTinh?: string | null, nameHuyen?: string | null, nameXa?: string | null) => {
    // Nếu không có nameTinh, sử dụng dữ liệu cấp tỉnh (ProvinceGeoJson)
    // Nếu có nameTinh và có nameHuyen hoặc nameXa, sử dụng dữ liệu cấp xã (CommuneGeoJson)
    // Nếu chỉ có nameTinh, sử dụng dữ liệu cấp huyện (DistrictGeoJson)
    const geoData = !nameTinh
      ? (ProvinceGeoJson as any).features
      : (nameHuyen || nameXa)
        ? (CommuneGeoJson as any).features
        : (DistrictGeoJson as any).features;

    return geoData?.reduce((result: any[], item: any) => {
      // Kiểm tra xem item có thuộc tỉnh,huyện,xã đang được tìm kiếm không
      const isMatchTinh = nameTinh ? cmpNormalizeString(item.properties[NAME_PROVINCE], nameTinh) : true;
      const isMatchHuyen = nameHuyen ? cmpNormalizeString(item.properties[NAME_DISTRICT], nameHuyen) : true;
      const isMatchXa = nameXa ? cmpNormalizeString(item.properties[NAME_COMMUNE], nameXa) : true;

      // Nếu tất cả các điều kiện đều đúng (có khớp với tỉnh, huyện, xã)
      if (isMatchTinh && isMatchHuyen && isMatchXa) {
        // Tìm kiếm dữ liệu tương ứng từ data.infoMapData.dataTimes dựa trên tên địa phương
        const itemData = data.infoMapData?.dataTimes?.find((dataItem: any) => {
          return cmpNormalizeString(item.properties[configMap.fieldViewLabel], dataItem?.[configMap.responseField])

        }
        );

        if (itemData) {
          result.push({
            ...item,
            properties: {
              ...item.properties,
              ...itemData,
            },
          });
        }
      }

      return result;
    }, []);
  };

  const highlightFeature = (e: LeafletEvent) => {
    const layer = e.target;
    const properties = layer.feature.properties;
    const fieldName = properties[configMap.fieldViewLabel];
    const tooltipContent = `<b>${fieldName}</b>`;
    layer.setStyle({
      weight: 2,
      color: 'orange',
      fillOpacity: 0.2,
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }

    layer.bindTooltip(tooltipContent, { permanent: false, direction: "top" }).openTooltip();

    setTimeout(() => {
      layer.closeTooltip();
    }, 3000);
  };

  const resetHighlight = (e: LeafletEvent) => {
    const layer = e.target;
    layer.setStyle({
      weight: 1,
      color: '#058bb4',
      fillOpacity: 0.1,
    })
  };

  const setStyleGeoJson = (feature: any) => {
    return {
      fillColor: data.infoMapData?.dataTimes?.filter((item: any) => cmpNormalizeString(item[configMap.responseField], feature.properties[configMap.fieldViewLabel]))?.[0]?.color,
      fillOpacity: 0.1,
      weight: 1,
      opacity: 1,
      color: "#058bb4"
    }
  }

  const iconRender = (cluster: any) => {
    return {
      html: `<div class="data-point-default" style="background:${cluster?.properties?.color};"}>${cluster?.properties?.point_count || ""} </div>`
    }
  }

  const iconRender2 = (cluster: any) => {
    return {
      html: `<div class="data-point-default" style="width:30px; height: 30px;z-index: 2; background:${cluster?.properties?.color};">${cluster?.properties?.total ?? cluster?.properties?.value} </div>`
    }
  }

  const popupRender = (cluster: any) => {
    return <Popup>
      <div>
        {cluster?.properties?.hoTen}
      </div>
    </Popup>
  }
  const popupRender2 = (cluster: any) => {
    return <Popup>
      <div className='text-primary'>
        {cluster?.properties?.[configMap.responseField]}
      </div>
      <span>Tổng số ca mắc trong tuần  <span className='text-danger'>{cluster?.properties?.total}</span></span>
    </Popup>
  }

  const handleClickFeature = (e: LeafletEvent) => {
    const { properties } = e.target.feature;
    const tinh = properties[NAME_RES_PROVINCE];
    const huyen = properties[NAME_RES_DISTRICT];
    const xa = properties[NAME_RES_COMMUNE];
    (properties?.viDoTrungDiem && properties?.kinhDoTrungDiem) &&
      setCenterMap([properties?.viDoTrungDiem, properties?.kinhDoTrungDiem]);
    setAddress?.((prev: any) => {
      return {
        ...prev,
        tinhId: properties?.tinhId,
        huyenId: properties?.huyenId,
        xaId: properties?.xaId,
        tenTinh: tinh,
        tenHuyen: huyen,
        tenXa: xa
      }
    })
  };

  const getAddress = (newAddress: any, name: string) => newAddress?.[name] || userData?.[name]

  const getAddressLevel = (address:any) => {
    if (getAddress(address, "tenXa")) return CAP_USER.XA;
    if (getAddress(address, "tenHuyen")) return CAP_USER.HUYEN;
    if (getAddress(address, "tenTinh")) return CAP_USER.TINH;
    return CAP_USER.TRUNG_UONG;
  };

  useEffect(() => {
    setConfigMap(configMapByHinhThuc[getAddressLevel(address)]);
  }, [address])

  useEffect(() => {
    if (data.infoMapData?.dataTimes.length) {
      const currGeo = getGeoJsonData(
        getAddress(address, "tenTinh"),
        getAddress(address, "tenHuyen"),
        getAddress(address, "tenXa"));
      setGeoJsonData(currGeo);
    }
  }, [data.infoMapData, ProvinceGeoJson])

  const dataPoints = [{
    points: data?.infoMapData?.dataThbs?.filter((item: any) => item.kinhDo && item.viDo).map((item: any) => {
      return {
        ...item,
        position: [item.kinhDo, item.viDo],
        color: item?.color
      }
    }) || [],
    radiusCluster: 100,
    maxZoomCluster: 16,
    levelZoomToShow: 7,
    renderIconCluster: iconRender,
    popupRender: popupRender
  },
  {
    points: data?.infoMapData?.dataTimes?.filter((item: any) => item.kinhDoTrungDiem && item.viDoTrungDiem).map((item: any) => {
      return {
        ...item,
        position: [item.kinhDoTrungDiem, item.viDoTrungDiem],
        color: item?.color
      }
    }) || [],
    radiusCluster: 100,
    maxZoomCluster: 16,
    levelZoomToShow: 7,
    renderIconCluster: iconRender2,
    popupRender: popupRender2
  }
  ]

  useEffect(() => {
    const lat = data.inforTQGS?.location?.viDo;
    const ing = data.inforTQGS?.location?.kinhDo;
    (lat && ing) && setCenterMap([lat, ing]);
  }, [data.inforTQGS])


  return (
    <>
      <div className="spaces p-5 text-uppercase bg-dark">
          <AreaBar />
      </div>
      <OCTMap
        center={centerMap}
        height='calc(100vh - 280px)'
        width='100%'
        keyGeoJson={`${geoJsonData}`}
        keyMap={`${props?.defaultActiveKey}${centerMap}`}
        dataPoints={dataPoints || []}
        tileLayerUrl='https://maps.vietmap.vn/api/dm/{z}/{x}/{y}@2x.png?apikey=0344e4a08f31899b9fc0ac0db97f4685e6169cd63532164b'
        geoJsonData={geoJsonData}
        zoom={configMap?.zoomDefault}
        handleMouseOverFeature={highlightFeature}
        setStyleGeoJson={setStyleGeoJson}
        handleClickFeature={handleClickFeature}
        handleMouseOutFeature={resetHighlight}
      />
    </>
  )
}

export default BanDoGamSat
