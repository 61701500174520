import moment from "moment";
import { renderStatusThb } from "../../../utils/FormatUtils";
import { GENDER_OPT, PHAN_LOAI_CHAN_DOAN, SU_DUNG_VAXIN, YES_NO_OPT } from "../../danh-sach-truong-hop-benh/constants/constant";
import { IDataResImport } from "../../danh-sach-truong-hop-benh/model/Model";
import { ISearchObjectModel } from "../../models/TimKiemTruongHopBenhModels";
import { formatDateToString } from './../../../utils/FormatUtils';
import { getIntlFromLocalStorage } from "../../../utils/FunctionUtils";
const intl = getIntlFromLocalStorage()
export const truongHopBenhColumns = (intl: any) => [
  {
    name: intl.formatMessage({ id: "ORDER" }),
    field: "stt",
    render: (row: any, index: number, stt: number) => <span>{stt}</span>,
  },
  {
    name: intl.formatMessage({ id: "FULLNAME" }),
    field: "hoTen",
    headerStyle: {
      minWidth: "140px",
    },
    cellStyle: {
      textAlign: "left",
    },
  },
  {
    name: intl.formatMessage({ id: "DIAGNOSIS" }),
    field: "phanLoaiChanDoan",
    headerStyle: {
      minWidth: "120px",
    },
    cellStyle: {
      textAlign: "center",
    },
    render: (rowData: any) => PHAN_LOAI_CHAN_DOAN.find((item) => item.code === rowData?.phanLoaiChanDoan)?.name,
  },
  {
    name: intl.formatMessage({ id: "STATUS_PRESENT" }),
    field: "tinhTrangHienNay",
    headerStyle: {
      minWidth: "120px",
    },
    cellStyle: {
      textAlign: "left",
    },
    render: (rowData: any) => TINH_TRANG_HIEN_NAY.find((item) => item.code === rowData?.tinhTrangHienNay)?.name,
  },
  {
    name: intl.formatMessage({ id: "PROVINCE" }),
    field: "tenTinh",
    headerStyle: {
      minWidth: "120px",
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    name: intl.formatMessage({ id: "DISTRICT" }),
    field: "tenHuyen",
    headerStyle: {
      minWidth: "120px",
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    name: intl.formatMessage({ id: "COMMUNE" }),
    field: "tenXa",
    headerStyle: {
      minWidth: "120px",
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    name: intl.formatMessage({ id: "TREATMENT_FACILITY" }),
    field: "tenCoSoDieuTri",
    headerStyle: {
      minWidth: "120px",
    },
    cellStyle: {
      textAlign: "left",
    },
  },
  {
    name: intl.formatMessage({ id: "CONFIRMED_FACILITY" }),
    field: "tenCoSoGhiNhan",
    headerStyle: {
      minWidth: "120px",
    },
    cellStyle: {
      textAlign: "left",
    },
  },
  {
    name: intl.formatMessage({ id: "DATE_OF_DESEASE_ONSET" }),
    field: "ngayKhoiPhat",
    headerStyle: {
      minWidth: "120px",
    },
    cellStyle: {
      textAlign: "center",
    },
    render: (rowData: any) => rowData?.ngayKhoiPhat && moment(rowData?.ngayKhoiPhat).format("DD-MM-YYYY"),
  },
  {
    name: intl.formatMessage({ id: "DATE_TO_HOSPITAL" }),
    field: "ngayNhapVien",
    headerStyle: {
      minWidth: "120px",
    },
    cellStyle: {
      textAlign: "center",
    },
    render: (rowData: any) => rowData?.ngayNhapVien && moment(rowData?.ngayNhapVien).format("DD-MM-YYYY"),
  },
  {
    name: intl.formatMessage({ id: "REPORT_DATE" }),
    field: "ngayTao",
    headerStyle: {
      minWidth: "120px",
    },
    cellStyle: {
      textAlign: "center",
    },
    render: (rowData: any) => rowData?.ngayTao && moment(rowData?.ngayTao).format("DD-MM-YYYY"),
  },
  {
    name: intl.formatMessage({ id: "STATUS" }),
    field: "isQuaHanNopBaoCao",
    headerStyle: {
      minWidth: "50px",
    },
    cellStyle: {
      textAlign: "center",
    },
    render: (rowData: any) =>  renderStatusThb(rowData.isQuaHanNopBaoCao)
  },
];

export const GENDER_OPTION = [
  { name: "Nam", code: 0 },
  { name: "Nữ ", code: 1 },
];

export const SEARCH_OBJECT_INIT: ISearchObjectModel = {
  keyword: null,
  thoiGianBatDau: null,
  thoiGianKetThuc: null,
  benhIds: null,
  listTrangThai: null,
  tinh: null,
  huyen: null,
  xa: null,
  hoTen: null,
  gioiTinh: null,
  ngheNghiep: null,
  tuNgayKhoiPhat: null,
  denNgayKhoiPhat: null,
  tuNgayNhapVien: null,
  denNgayNhapVien: null,
  tuNgayRaVien: null,
  denNgayRaVien: null,
  kqXetNghiem: null,
  tuNgayLayMau: null,
  denNgayLayMau: null,
  donViThucHienXn: null,
  tuNgayTraKetQuaXn: null,
  denNgayTraKetQuaXn: null,
  coSoDieuTri: null,
  isNopTroLen: null,
  pageNumber: 1,
  pageSize: 10,
  phanLoaiQuanLy: {
    code: 0,
    name: "Tất cả"
  },
  listTinhTrangHienNay: [],
  tuNgayNhapBaoCao: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
  denNgayNhapBaoCao: moment().format('YYYY-MM-DD'),
  coSoGhiNhan: null,
};

export const TINH_TRANG_HIEN_NAY = [
  {
    code: 0,
    name: "Điều trị ngoại trú",
  },
  {
    code: 1,
    name: "Điều trị nội trú",
  },
  {
    code: 2,
    name: "Ra viện",
  },
  {
    code: 3,
    name: "Tử Vong",
  },
  {
    code: 4,
    name: "Chuyển viện",
  },
  {
    code: 5,
    name: "Tình trạng khác",
  },
];

export const LIST_TRANG_THAI = [
  {
    code: 0,
    name: "nặng",
  },
  {
    code: 1,
    name: "Khỏi bệnh",
  },
  {
    code: 2,
    name: "Bình thường",
  },
];

export const KQ_XET_NGHIEM = [
  {
    code: 0,
    name: "Dương tính",
  },
  {
    code: 1,
    name: "Âm tính",
  },
  {
    code: 2,
    name: "Chưa có kết quả",
  },
  {
    code: 3,
    name: "Không thực hiện",
  },
];

export const PHAN_LOAI_QUAN_LY = [
  {
    code: 0,
    name: "Tất cả",
  },
  {
    code: 1,
    name: "Cơ sở tự nhập",
  },
  {
    code: 2,
    name: "Chuyến đến",
  },
  {
    code: 3,
    name: "Chuyến đi",
  },
  {
    code: 4,
    name: "Cơ sở quản lý",
  },
];

export const truongHopBenhExcelColumns = (props:any) => {
    const { handleOpenEditTHB, ttLoi = false, intl } = props

    const getNameByCode = (options: { code: number, name: string }[], value: any) => {
        return options.find(({ code }) => code === value)?.name
    }

    return [
        {
            name: `${intl.formatMessage({ id: "ORDER" })}`,
            field: "stt",
            render: (row: any, index: number, stt: number) => <span>{stt}</span>,
        },
        {
            name: `${intl.formatMessage({ id: "FULLNAME" })}`,
            field: "hoTen",
            headerStyle: {
                minWidth: "200px",
            },
            cellStyle: {
                textAlign: "left",
                color: handleOpenEditTHB ? "blue" : "black",
                cursor: "pointer",
            },
            render: (row: IDataResImport) => <span onClick={() => handleOpenEditTHB?.(row)}>{row.thb?.doiTuongMacBenh?.hoTen}</span>,
        },
        ...ttLoi ? [{
            name: `${intl.formatMessage({ id: "INFORMATION_INVALID" })}`,
            field: "tt",
            headerStyle: {
                minWidth: "200px",
            },
            cellStyle: {
                textAlign: "left",
            },
            render: (row: any) => <span className="white-sp-preline text-danger">{row?.thongTinLoi}</span>,
        }] : [],
        {
            name: `${intl.formatMessage({ id: "MANAGEMENT_CODE_NUMBER" })}`,
            field: "maSoQuanLy",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{row.thb?.doiTuongMacBenh?.maSoTt54}</span>,
        },
        {
            name: `${intl.formatMessage({ id: "ID_NUMBER" })}`,
            field: "cmnd",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{row.thb?.doiTuongMacBenh?.cmnd}</span>,
        },

        {
            name: `${intl.formatMessage({ id: "DATE_OF_BIRTH" })}`,
            field: "ngaySinh",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{formatDateToString(row.thb?.doiTuongMacBenh?.ngaySinh)}</span>,
        },
        {
            name: `${intl.formatMessage({ id: "GENDER" })}`,
            field: "gioiTinh",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{getNameByCode(GENDER_OPT(intl), row.thb?.doiTuongMacBenh?.gioiTinh)}</span>,
        },
        {
            name: `${intl.formatMessage({ id: "ETHNICITY" })}`,
            field: "danToc",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{row.thb?.doiTuongMacBenh?.danTocTen}</span>,
        },
        {
            name: `${intl.formatMessage({ id: "WORK" })}`,
            field: "ngheNghiep",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{row.thb?.doiTuongMacBenh?.ngheNghiepTen}</span>,
        },
        {
            name: `${intl.formatMessage({ id: "PROVINCE_ADDRESS_PRESENT" })}`,
            field: "tinhNoiOHienNay",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{row.thb?.doiTuongMacBenh?.tinhTenHienNay}</span>,
        },
        {
            name: `${intl.formatMessage({ id: "DISTRICT_ADDRESS_PRESENT" })}`,
            field: "huyenNoiOHienNay",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{row.thb?.doiTuongMacBenh?.huyenTenHienNay}</span>,

        },
        {
            name: `${intl.formatMessage({ id: "COMMUNE_ADDRESS_PRESENT" })}`,
            field: "xaNoiOHienNay",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{row.thb?.doiTuongMacBenh?.xaTenHienNay}</span>,

        },
        {
            name: `${intl.formatMessage({ id: "ADDRESS_PRESENT" })}`,
            field: "diaChiNoiOHienNay",
            headerStyle: {
                minWidth: "200px",
            },
            cellStyle: {
                textAlign: "left",
            },
            render: (row: IDataResImport) => <span>{row.thb?.doiTuongMacBenh?.diaChiHienNay}</span>,

        },
        {
            name: `${intl.formatMessage({ id: "WORKPLACE_STUDY" })}`,
            field: "diaChiNoiLamViec",
            headerStyle: {
                minWidth: "200px",
            },
            cellStyle: {
                textAlign: "left",
            },
            render: (row: IDataResImport) => <span>{row.thb?.doiTuongMacBenh?.noiLamViecHocTap}</span>,

        },
        {
            name: `${intl.formatMessage({ id: "PHONE_NUMBER" })}`,
            field: "soDienThoai",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{row.thb?.doiTuongMacBenh?.dienThoai}</span>,

        },
        {
            name: `${intl.formatMessage({ id: "ICD_DESEASE_DIAGNOSI" })}`,
            field: "benhChanDoanIcd",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{row.thb?.truongHopBenh?.benhTruyenNhiemTen}</span>,

        },
        {
            name: `${intl.formatMessage({ id: "USE_VACCINE" })}`,
            field: "suDungVacxin",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{getNameByCode(SU_DUNG_VAXIN, row.thb?.truongHopBenh?.suDungVacXin)}</span>,
        },
        {
            name: `${intl.formatMessage({ id: "TOTAL_INJECTION_DRINK" })}`,
            field: "soLanTiemUong",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{row.thb?.truongHopBenh?.soLanSuDung}</span>,

        },
        {
            name: `${intl.formatMessage({ id: "DIAGNOSIS_CLASSIFY" })}`,
            field: "phanLoaiChanDoan",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{getNameByCode(PHAN_LOAI_CHAN_DOAN, row.thb?.truongHopBenh?.phanLoaiChanDoan)}</span>,


        },
        {
            name: `${intl.formatMessage({ id: "GET_DIAGNOSIS_SPECIMEN" })}`,
            field: "layMauXetNghiem",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{getNameByCode(YES_NO_OPT(intl), row.thb?.truongHopBenh?.layMauXetNghiem)}</span>,

        },
        {
            name: `${intl.formatMessage({ id: "SPECIMEN_CLASSIFY" })}`,
            field: "loaiXetNghiem",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{getNameByCode(YES_NO_OPT(intl), row.thb?.truongHopBenh?.layMauXetNghiem)}</span>,


        },
        {
            name: `${intl.formatMessage({ id: "SPECIMEN_RESULT" })}`,
            field: "ketQuaXetNghiem",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{getNameByCode(KQ_XET_NGHIEM, row.thb?.truongHopBenh?.ketQuaXetNghiem)}</span>,

        },
        {
            name: `${intl.formatMessage({ id: "DATE_OF_DESEASE_ONSET" })}`,
            field: "ngayKhoiPhat",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{formatDateToString(row.thb?.truongHopBenh?.ngayKhoiPhat)}</span>,
        },
        {
            name: `${intl.formatMessage({ id: "DATE_IN_HOSPITAL_OR_EXAMINE" })}`,
            field: "ngayNhapVien",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{formatDateToString(row.thb?.truongHopBenh?.ngayNhapVien)}</span>,

        },
        {
            name: `${intl.formatMessage({ id: "DATE_OUT_HOSPITAL" })}`,
            field: "ngayRaVien",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{formatDateToString(row.thb?.truongHopBenh?.ngayRaVien)}</span>,

        },
        {
            name: `${intl.formatMessage({ id: "STATUS_PRESENT" })}`,
            field: "tinhTrangHienNay",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{getNameByCode(TINH_TRANG_HIEN_NAY, row.thb?.truongHopBenh?.tinhTrangHienNay)}</span>,

        },
        {
            name: `${intl.formatMessage({ id: "NBC_NAME" })}`,
            field: "hoTenNbc",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{row.thb?.truongHopBenh?.tenNguoiBaoCao}</span>,

        },
        {
            name: `${intl.formatMessage({ id: "NBC_PHONE_NUMBER" })}`,
            field: "soDienThoaiNbc",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{row.thb?.truongHopBenh?.dienThoaiNguoiBaoCao}</span>,

        },
        {
            name: `${intl.formatMessage({ id: "EMAIL" })}`,
            field: "emailNbc",
            headerStyle: {
                minWidth: "140px",
            },
            render: (row: IDataResImport) => <span>{row.thb?.truongHopBenh?.emailNguoiBaoCao}</span>,

        },
    ];
}
