import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { getInforDashboardDuBao } from '../../services/services';
import { useInitContext } from '../../../utils/InitContext';
import moment from 'moment';

const BieuDoCaMacTheoDanSo: React.FC = (props: any) => {
  const { address } = useInitContext()
  const [chartData, setChartData] = useState<any>([])

  const chartRef = useRef<HTMLDivElement>(null);
  const [expandedWeeks, setExpandedWeeks] = useState<string[]>([]);

  const getChartData = () => {
    const xData: string[] = [];
    const yData: number[] = [];

    chartData[0]?.forEach((week: any) => {
      xData.push(week.week);
      yData.push(week.value);

      if (expandedWeeks.includes(week.week)) {
        week.days.forEach((day: any) => {
          xData.push(day.day);
          yData.push(day.value);
        });
      }
    });

    return { xData, yData };
  };

  const toggleExpand = (week: string) => {
    setExpandedWeeks(prevState =>
      prevState.includes(week)
        ? prevState.filter(w => w !== week)
        : [...prevState, week]
    );
  };

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current!, 'dark');

    const updateChart = () => {
      const { xData, yData } = getChartData();

      chartInstance.setOption({
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          data: xData,
          axisLabel: {
            formatter: function (value: string) {
              return value;
            },
            tooltip: {
              formatter: function (value: string) {
                console.log(value);
                return value;
              },
            },
            rich: {
              week: {
                color: '#333',
                fontSize: 12,
                align: 'center',
                padding: [0, 0, 0, 10],
                cursor: 'pointer',
                textBorderColor: '#fff',
                textBorderWidth: 2
              }
            }
          }
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        yAxis: {
          type: 'value',
        },
        series: [{
          name: 'Data',
          type: 'line',
          data: yData,
          barWidth: '50%',
        }]
      });

      // Xử lý sự kiện nhấp chuột vào nhãn trên trục X
      chartInstance.getZr().on('click', function (event) {
        const pointInPixel = [event.offsetX, event.offsetY];
        const pointInGrid = chartInstance.convertFromPixel('grid', pointInPixel);

        if (pointInGrid.length > 0) {
          const xAxisIndex = Math.floor(pointInGrid[0]);
          if (xAxisIndex >= 0 && xAxisIndex < xData.length) {
            const weekLabel = xData[xAxisIndex];

            if (weekLabel.startsWith('W')) {
              const weekName = weekLabel;
              toggleExpand(weekName);
            }
          }
        }
      });
    };

    updateChart();

    return () => {
      chartInstance.dispose();
    };
  }, [expandedWeeks, props.defaultActiveKey, chartData]);

  const getDataChart = async () => {
    const res = await getInforDashboardDuBao({
      type: "day",
      provinceName: address?.tenTinh,
      districtName: address?.tenHuyen,
      communeName: address?.tenXa,
      years: moment().year().toString()
    }, "case/case-per-population")
    setChartData(res.data)
  }

  useEffect(() => {
    getDataChart()
  }, [address])

  return (
    <div>
      <div ref={chartRef} style={{ width: "100%", height: '500px' }} />
    </div>
  );
};

export default BieuDoCaMacTheoDanSo;
