import moment from 'moment'
import { useInitContext } from '../../../utils/InitContext'
import { getInforDashboardDuBao } from '../../services/services'
import CustomChart from '../CustomChart'
import { useEffect, useState } from 'react'

const BieuDocaMacTuan = () => {
  const { address } = useInitContext()
  const [data, setData] = useState<any>({})
  const transformData = (data: any) => {
    const result: any = {
      weeks: Array.from({ length: 53 }, (_, i) => `W${i + 1}`),
      valuesByYear: {}
    };

    for (const year in data) {
      result.valuesByYear[year] = Object.values(data[year]);
    }

    return result;
  };

  const averageFromYear = data?.infoEpidemicCurve?.fromYear
  const averageToYear = data?.infoEpidemicCurve?.toYear
  const lengendaverage = (averageFromYear && averageToYear) ? (averageFromYear + ' - ' + averageToYear) : ""
  const currentYear = moment().year();
  const recentYearsString = Array.from({ length: 3 }, (_, index) => currentYear - index).reverse().join(',');

  const init = async () => {
    try {

      Promise.allSettled([
        getInforDashboardDuBao({
          type: "Week",
          years: recentYearsString,
          provinceName: address?.tenTinh,
          districtName: address?.tenHuyen,
          communeName: address?.tenXa
        }, "case/case-by-time"),
        getInforDashboardDuBao({
          postal: [address?.tenXa, address?.tenHuyen, address?.tenTinh, "Việt Nam"].filter(value => value).join(", "),
          type: "Week",
          level0: "Việt Nam",
          level1: address?.tenTinh,
          level2: address?.tenHuyen,
          level3: address?.tenXa
        }, "epidemic-curve/one-by-address"),
      ])
        .then(results => {
          const [infoCaseByWeek, infoEpidemicCurve] = results;
          setData((prev: any) =>
          ({
            infoCaseByWeek: infoCaseByWeek.status === 'fulfilled' ? infoCaseByWeek.value?.data : prev.infoCaseByWeek,
            infoEpidemicCurve: infoEpidemicCurve.status === 'fulfilled' ? infoEpidemicCurve.value?.data : prev.infoEpidemicCurve
          })
          )
        })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    init()
  }, [address])

  return (
    <>
      <CustomChart height='calc(100vh - 311px)' config={{
        title: {
          textStyle: {
            color: 'orange',
            fontFamily: 'sans-serif',
            fontSize: 14,
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [...Object.keys(transformData(data?.infoCaseByWeek)?.valuesByYear), lengendaverage],
          textStyle: {
            color: 'orange',
            fontSize: 12,
          },
          top: "bottom",
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '8%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: transformData(data?.infoCaseByWeek)?.weeks
        },
        yAxis: {
          type: 'value',
        },
        series: [
          ...Object.entries(transformData(data?.infoCaseByWeek)?.valuesByYear)?.map(([year, values]: [string, any]) => ({
            name: year,
            type: 'line',
            data: values,
          })),
          {
            name: lengendaverage,
            type: 'line',
            data: Object.values(data?.infoEpidemicCurve?.averageCases || {}),
          }]
      }
      } />
    </>
  )
}

export default BieuDocaMacTuan
