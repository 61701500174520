import { Col, Row} from "react-bootstrap"
import { useEffect, useRef, useState } from "react"
import "../../dashboard.scss"
import DashboardHeader from "../DashboardHeader";
import DashboardGiamSatDetail from "./DashboardGiamSatDetail";
import { InitContext } from "../../../utils/InitContext";
import { IDataDashboard } from "../../model/model";
import { getInforDashboardDuBao, getInforDashboardGiamSat } from "../../services/services";
import { getWeekRange } from "../../../utils/FunctionUtils";
import { useAppContext, useLoading } from "../../../../AppContext";
import moment from "moment";
import { localStorageItem } from "../../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";

interface IProps {}

const DashboardGiamSat = (props: IProps) => {
  const { setPageLoading } = useLoading()
  const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION)
  const [address, setAddress] = useState({
    id: null,
    isActive: null,
    maTinh: null,
    tenTinh: userData?.tenTinh,
    tinhId: null,
    huyenId: null,
    tenHuyen: userData?.tenHuyen,
    tenXa: userData?.tenXa,
    xaId: null,
    thonId: null,
    tenThon:userData?.tenThon,
    vungMienId: null,
  });
  const [data, setData] = useState({} as IDataDashboard);
  const paramsRef = useRef<any>({})

  const init = async () => {
  try {
      setPageLoading(true)
      let arrayAdress = [];
      let { startOfWeek, endOfWeek } = await getWeekRange()
      if (!address || !address.tinhId) {
        arrayAdress = [userData?.thonId, userData?.xaId, userData?.huyenId, userData?.tinhId];
      } else {
        arrayAdress = [address?.thonId, address?.xaId, address?.huyenId, address?.tinhId];
      }
      paramsRef.current = { tuNgay: startOfWeek, denNgay: endOfWeek, xaId: null, huyenId: null, tinhId: null, thonId: null, loaiBaoCao: 1 };
      for (let index = 0; index < arrayAdress.length; index++) {
        if (index === 0 && arrayAdress[index]) {
          paramsRef.current.thonId = arrayAdress[index]
        } else if (index === 1 && arrayAdress[index]) {
          paramsRef.current.xaId = arrayAdress[index]
          break
        } else if (index === 2 && arrayAdress[index]) {
          paramsRef.current.huyenId = arrayAdress[index]
          break
        } else if (index === 3 && arrayAdress[index]) {
          paramsRef.current.tinhId = arrayAdress[index]
        }
      }
      Promise.allSettled([
        getInforDashboardGiamSat(paramsRef.current, "dashboards/tong-quan-giam-sat"),
        getInforDashboardGiamSat(paramsRef.current, "dashboards/danh-sach-dia-phuong-giam-sat"),
        getInforDashboardGiamSat(paramsRef.current, "dashboards/danh-sach-thb"),
        getInforDashboardGiamSat(paramsRef.current, "dashboards/danh-sach-o-dich"),
      ])
        .then(results => {
          const [inforTQGS, inforDSDPGS, infoMapData, infoODich] = results;
          setData((prev: any) =>
          ({
            inforTQGS: inforTQGS.status === 'fulfilled' ? inforTQGS.value?.data?.data : prev.inforTQGS,
            inforDSDPGS: inforDSDPGS.status === 'fulfilled' ? inforDSDPGS.value?.data?.data : prev.inforDSDPGS,
            infoMapData: infoMapData.status === 'fulfilled' ? infoMapData.value?.data?.data : prev.infoMapData,
            infoODich: infoODich.status === 'fulfilled' ? infoODich.value?.data?.data : prev.infoODich,
          })
          )
        })
    } catch (error) {
      console.error(error)
    } finally {
      setTimeout(() => setPageLoading(false), 100);
    }
  }

  useEffect(() => { // expand map
    const element = document.getElementById("icon-expand");
    const elementMap = document.getElementsByClassName("tab-content");

    if (elementMap.length > 0) {
        const map = elementMap[0] as HTMLElement; // Ensure it's treated as an HTMLElement
        const elementExpand = element as HTMLElement; // Ensure it's treated as an HTMLElement

        const handleClick = () => {
            const requestFullscreen =
                map.requestFullscreen?.bind(map) ||
                (map as any).mozRequestFullScreen?.bind(map) || // Firefox
                (map as any).webkitRequestFullscreen?.bind(map) || // Chrome, Safari and Opera
                (map as any).msRequestFullscreen?.bind(map); // IE/Edge

            if (requestFullscreen) {
                requestFullscreen();
            }
        };

        elementExpand?.addEventListener('click', handleClick);

        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                console.log('Exited fullscreen mode');
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
          elementExpand.removeEventListener('click', handleClick);
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }
}, []);



  useEffect(() => {
    // Lấy dữ liệu dashboard khi address thay đổi
    init()
  }, [address])

  return (
    <InitContext.Provider value={{ address, setAddress, data }}>
      <div className="dash-board-container">
        <DashboardHeader hasIntervention={false} src='/media/logos/edengue.png' label='BẢNG THÔNG TIN Giám Sát DỊCH SỐT XUẤT HUYẾT' address='TP cần thơ' />
        <DashboardGiamSatDetail/>
      </div>
    </InitContext.Provider>
  )
}

export default DashboardGiamSat;
