import { OCTKTSVG, OCTTable } from "@oceantech/oceantech-ui";
import "./styles/danhSachThb.scss";
import { TYPE } from "../../utils/Constant";
import { danhSachThbColumns, getExportedFileList,TRANG_THAI_PHAN_HOI, INIT_TRUONG_HOP_BENH, HINH_THUC_CO_SO, TU_VONG_STATUS } from "./constants/constant";
import { Button } from "react-bootstrap";
import InputSearch from "../../component/input-field/InputSearch";
import ThongTinThb from "./components/ThongTinThb";
import NhapTruongHopBenhModal from './components/NhapTruongHopBenhModal';
import { useContext, useEffect, useState } from "react";
import TimKiemTHBNangCaoModal from "./components/TimKiemTHBNangCaoModal";
import { toast } from "react-toastify";
import ModalXacNhanTHB from "./components/ModalXacNhanTHB";
import { ISearchObjectModel } from "../models/TimKiemTruongHopBenhModels";
import { searchThbByPage } from "../tim-kiem-truong-hop-benh/services/TimKiemThbServices";
import { IDropdownButton, TruongHopBenh } from "./model/Model";
import { deleteTruongHopBenh, getThongTinTruongHopBenh } from "./servives/Services";
import { SEARCH_OBJECT_INIT } from "../tim-kiem-truong-hop-benh/constants/constants";
import ConfirmDialog from "../../component/confirm-dialog/ConfirmDialog";
import DropdownButton from "../../component/button/DropdownButton";
import { convertListSearchObject, formatDataViewTHB, hasAuthority } from "../../utils/FunctionUtils";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { handleChangePagination } from "../../utils/PageUtils";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../Constant";
import { getThongTinUser } from "../../accounts/services/serviceTest";
import { useLoading } from "../../../AppContext";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import CustomDialog from "../../component/dialogs/CustomDialog";

const DanhSachTruongHopBenh = () => {
    const { setPageLoading } = useLoading();
    const [openTruongHopBenhForm, setOpenTruongHopBenhForm] = useState<boolean>(false);
    const [openDeleteTruongHopBenh, setOpenDeleteTruongHopBenh] = useState<boolean>(false);
    const [openSearchAdvanceDialog, setOpenSearchAdvanceDialog] = useState<boolean>(false);
    const [shouldOpenXacNhanThbDialog, setShouldOpenXacNhanThbDialog] = useState<boolean>(false);
    const [truongHopBenhList, setTruongHopBenhList] = useState<any>([]);
    const [searchObject, setSearchObject] = useState<ISearchObjectModel>(SEARCH_OBJECT_INIT);
    const [dataRow, setDataRow] = useState<TruongHopBenh>(INIT_TRUONG_HOP_BENH);
    const [dataForm, setDataForm] = useState<TruongHopBenh>(dataRow);
    const [configTable, setConfigTable] = useState<any>({});
    const [searchKeyword, setsSearchKeyword] = useState<string>("");
    const [exportedFileList, setExportedFileList] = useState<IDropdownButton[]>([]);
    const [openDetailTHB, setOpenDetailTHB] = useState(false);
    const userData = localStorageItem.get(KEY_LOCALSTORAGE.USER_INFOMATION);
    const elementAbility = {
        add: !!hasAuthority(PERMISSIONS.THB, PERMISSION_ABILITY.CREATE),
        import: !!hasAuthority(PERMISSIONS.THB, PERMISSION_ABILITY.CREATE),
        update: !!hasAuthority(PERMISSIONS.THB, PERMISSION_ABILITY.UPDATE),
        delete: !!hasAuthority(PERMISSIONS.THB, PERMISSION_ABILITY.DELETE),
        confirm: !!hasAuthority(PERMISSIONS.THB, PERMISSION_ABILITY.CONFIRM),
    }
    const navigate = useNavigate();
    const intl = useIntl()

    useEffect(() => {
        setSearchObject((prev) => ({
            ...prev,
            tinh: userData?.tinhInfo ? userData?.tinhInfo : null,
            huyen: userData?.huyenInfo ? userData?.huyenInfo : null,
            xa: userData?.xaInfo ? userData?.xaInfo : null,
        }));
    }, []);

    const getTruongHopBenhList = async (selectFirstRow?: boolean) => {
        try {
            setPageLoading(true);
            let {
              listTinhTrangHienNay,
              tinh,
              huyen,
              xa,
              coSoDieuTri,
              donViThucHienXn,
              coSoGhiNhan,
              gioiTinh,
              ngheNghiep,
              kqXetNghiem,
              phanLoaiQuanLy,
              ...newSearchObject
            } = searchObject;
            const tinhTrangHienNay = convertListSearchObject(
              listTinhTrangHienNay as any[],
              "listTinhTrangHienNay"
            );

            const searchObjTemp: any = {
                ...newSearchObject,
                ...tinhTrangHienNay,
                gioiTinh,
                ngheNghiepId: ngheNghiep?.id,
                phanLoaiQuanLy: phanLoaiQuanLy?.code,
                tinhId: tinh?.id,
                huyenId: huyen?.id,
                xaId: xa?.xaId,
                coSoGhiNhanId: coSoGhiNhan?.id,
                kqXetNghiem,
                donViThucHienXn: donViThucHienXn?.id,
                coSoDieuTriId: coSoDieuTri?.id
            }
            const { data } = await searchThbByPage(searchObjTemp);
            handleSelectFirstRow(data?.data?.data, selectFirstRow);
            setConfigTable({
                totalPages: data?.data?.totalPages,
                totalElements: data?.data?.total,
                numberOfElements: data?.data?.numberOfElements,
            })
        } catch (error) {
            console.error(error);
            toast.error(error as string);
        } finally {
            setPageLoading(false);
        }
    }

    const handleSelectFirstRow = (data: any[], selectFirstRow?: boolean) => {
        let dataTemp = data;
        let id = dataRow?.truongHopBenh?.truongHopBenhId
        if (selectFirstRow) {
            dataTemp = data.map((item: any, index: number) => {
                return index === 0 ? { ...item, isChecked: true } : item;
            });
            id = dataTemp?.[0]?.truongHopBenhId
        }
        setTruongHopBenhList(dataTemp);
        if (id) {
            getThongTinChiTietTHB(String(id));
        }
        else {
            setDataRow(INIT_TRUONG_HOP_BENH);
        }
    };

    const handleDeleteTruongHopBenh = async () => {
        const id = dataRow?.truongHopBenh?.truongHopBenhId
        try {
            setPageLoading(true);
            id && await deleteTruongHopBenh(id);
            getTruongHopBenhList(true);
            setOpenDeleteTruongHopBenh(false);
            toast.success("Xóa thành công")
        } catch (error) {
            console.error(error);
            toast.error(error as string);
        } finally {
            setPageLoading(false);
        }
    }


    const getThongTinChiTietTHB = async (id: string) => {
        try {
            setPageLoading(true);
            const rest = await getThongTinTruongHopBenh(id);
            setDataRow(formatDataViewTHB(rest.data.data));
        } catch (error) {

        }
        finally {
            setPageLoading(false);
        }
    }

    const handleSelectTHB = (row: any[]) => {
        getThongTinChiTietTHB(row[0]?.truongHopBenhId);
        setOpenDetailTHB(true)
    }

    const handleEditTHB = () => {
        setDataForm(dataRow)
        setOpenTruongHopBenhForm(true)
    }

    useEffect(() => {
        getTruongHopBenhList(true);
    }, [searchObject])

    useEffect(()=>{
        setExportedFileList(getExportedFileList(dataRow, setPageLoading, intl));
    }, [dataRow])

    const isChuaXacNhanOrXacNhanSaiThongTin = [
        TRANG_THAI_PHAN_HOI.CHUA_XAC_NHAN,
        TRANG_THAI_PHAN_HOI.XN_SAI_THONG_TIN_HANH_CHINH,
        TRANG_THAI_PHAN_HOI.XN_SAI_THONG_TIN_CHAN_DOAN
    ].includes(dataRow?.truongHopBenh?.trangThaiPhanHoi as number);
    const isTuyenHuyenOrTuyenXa = userData?.phanLoaiCoSo === HINH_THUC_CO_SO.TuyenHuyen
    || userData?.phanLoaiCoSo === HINH_THUC_CO_SO.TuyenXa;

    return (
        <div className="page-container d-flex flex-wrap spaces  ">
            <div className="left-content-container d-flex flex-1 flex-column">
                <div className="">
                    <div className="ds-header d-flex flex-wrap justify-content-between align-items-center">
                        <div className="d-flex align-items-center mb-2 gap-2">
                            <i className="spaces fs-18 bi bi-people-fill text-primary"></i>
                            <span className="title color-dark-red spaces fs-16">
                                {intl.formatMessage({ id: "PATIENTS" })}
                            </span>
                        </div>
                        {
                            elementAbility?.add &&
                            <div className="d-flex flex-wrap flex-end gap-2">
                                <Button
                                    className="button-primary"
                                    onClick={() => navigate("/nhap-tu-excel")}
                                >
                                  <i className="bi bi-file-earmark-spreadsheet"></i>
                                        {intl.formatMessage({ id: "IMPORT_PATIENT" })}
                                </Button>
                                <Button
                                    className="button-primary"
                                    onClick={() => {
                                        setDataForm(INIT_TRUONG_HOP_BENH)
                                        setOpenTruongHopBenhForm(true)
                                    }}
                                >
                                    <i className="bi bi-person-plus-fill"></i>
                                    {intl.formatMessage({ id: "ADD" })}
                                </Button>
                            </div>
                        }
                    </div>
                    <div className="ds-search-box d-flex flex-end">
                        <div className="box-search">
                            <InputSearch
                                placeholder={intl.formatMessage({ id: "SEARCHBY_NAME_PATIENTNUMBER_AND_MORE" })}
                                handleChange={(e) => setsSearchKeyword(e.target.value)}
                                className="spaces h-32"
                                value={searchKeyword}
                                handleSearch={() => {
                                    setSearchObject({ ...searchObject, keyword: searchKeyword })
                                }}
                            />
                        </div>
                        <Button
                            className="button-primary"
                            onClick={() => setOpenSearchAdvanceDialog(true)}
                        >
                            <OCTKTSVG path='/media/svg/icons/search.svg' svgClassName='spaces h-14 w-14' />
                            {intl.formatMessage({ id: "ADVANCE_SEARCH" })}
                        </Button>
                    </div>
                </div>
                <div className="ds-thb">
                    <OCTTable
                        id="danh-sach-thb"
                        data={truongHopBenhList}
                        columns={danhSachThbColumns(intl)}
                        searchObject={searchObject}
                        setSearchObject={(table) => {
                            handleChangePagination(table, setSearchObject)
                        }}
                        type={TYPE.SINGLE}
                        justFilter={true}
                        fixedColumnsCount={1}
                        setDataChecked={handleSelectTHB}
                        notDelete={true}
                        notEdit={true}
                        noToolbar={true}
                        totalPages={configTable?.totalPages}
                        totalElements={configTable?.totalElements}
                        numberOfElements={configTable?.numberOfElements}
                        uniquePrefix="truongHopBenhId"
                        unSelectedAll={true}
                    />
                </div>
                <div className="spaces px-10">
                    {intl.formatMessage({ id: "NOTE" })}:
                    <div className="d-flex align-items-center spaces mt-4 line-height-16">
                        <OCTKTSVG path="/media/svg/icons/check-circle-fill.svg" svgClassName="spaces w-16 h-16 mr-10 color-bright-cyan"/>
                        <span>{intl.formatMessage({ id: "CONFIRMED" })}</span>
                    </div>
                    <div className="d-flex align-items-center spaces mt-4 line-height-16">
                        <OCTKTSVG path="/media/svg/icons/exclamation-triangle-fill.svg" svgClassName="spaces w-16 h-16 mr-10 color-dark-orange"/>
                        <span>{intl.formatMessage({ id: "CONFIRM_PENDING" })}</span>
                    </div>
                    <div className="d-flex align-items-center spaces mt-4 line-height-16">
                        <OCTKTSVG path="/media/svg/icons/exclamation-triangle-fill.svg" svgClassName="spaces w-16 h-16 mr-10 color-dark-red"/>
                        <span>{intl.formatMessage({ id: "DATE_OVER_CONFIRM_7" })}</span>
                    </div>
                    <div className="d-flex align-items-center spaces mt-4 line-height-16">
                        <OCTKTSVG path="/media/svg/icons/question-circle-fill.svg" svgClassName="spaces w-16 h-16 mr-10 color-steel-blue"/>
                        <span>{intl.formatMessage({ id: "ADMINISTRATIVE_INFORMATION_INVALID" })}</span>
                    </div>
                    <div className="d-flex align-items-center spaces mt-4 line-height-16">
                        <OCTKTSVG path="/media/svg/icons/question-circle-fill.svg" svgClassName="spaces w-16 h-16 mr-10 color-green"/>
                        <span>{intl.formatMessage({ id: "DIAGNOSIS_INFORMATION_INVALID" })}</span>
                    </div>
                    <div className="spaces mt-4">
                        <span className="color-dark-red fs-18"> {intl.formatMessage({ id: "RED_WORD" })} </span>
                        &nbsp; {intl.formatMessage({ id: "OUTDATE_REPORT_SUBMIT_OR_CLASSIFY" })}
                    </div>
                    <div className="spaces mt-4">
                        <i className="text-primary">{intl.formatMessage({ id: "ITALICS" })}</i> &nbsp; {intl.formatMessage({ id: "DRAF_SAVE" })}
                    </div>
                </div>
            </div>

            {openTruongHopBenhForm && (
                <NhapTruongHopBenhModal
                    handleClose={() => setOpenTruongHopBenhForm(false)}
                    dataRow={dataForm}
                    updatePageData={getTruongHopBenhList}
                />
            )}

            {openSearchAdvanceDialog && (
                <TimKiemTHBNangCaoModal
                    show={openSearchAdvanceDialog}
                    searchObject={searchObject}
                    setSearchObject={setSearchObject}
                    handleClose={() => setOpenSearchAdvanceDialog(false)}
                />
            )}

            {shouldOpenXacNhanThbDialog && (
                 <ModalXacNhanTHB
                    handleClose={() => setShouldOpenXacNhanThbDialog(false)}
                    dataRow={dataRow}
                    updatePageData={getTruongHopBenhList}
                 />
            )}
            {
                openDeleteTruongHopBenh && (
                    <ConfirmDialog
                        show={openDeleteTruongHopBenh}
                        onCloseClick={() => setOpenDeleteTruongHopBenh(false)}
                        onCancelClick={() => setOpenDeleteTruongHopBenh(false)}
                        onYesClick={handleDeleteTruongHopBenh}
                        title={intl.formatMessage({ id: "DELETE_PATIENT" })}
                        message={intl.formatMessage({ id: "DELETE_CONFIRM_PATIENT" })}
                        yes={intl.formatMessage({ id: "DELETE" })}
                        cancel={intl.formatMessage({ id: "CANCLE" })}
                    />

                )
            }

            {
                openDetailTHB &&
                <CustomDialog
                    handleSubmit={() => { }}
                    isShow={openDetailTHB}
                    setIsShow={setOpenDetailTHB}
                    initValue={{} as any}
                    validationSchema={{} as any}
                    isModalWrap={true}
                    isForm={false}
                    title={intl.formatMessage({ id: "PATIENT_INFO" })}
                >
                    <div className="right-content-container flex-1 spaces min-h-425">
                        <div className="title-wrapper d-flex justify-content-end align-items-center gap-2">
                            {/* <div>
                                <i className="bi bi-info-square-fill spaces fs-17 text-primary"></i>
                                <span className="title color-dark-red spaces fs-16">{intl.formatMessage({ id: "PATIENT_INFO" })}</span>
                            </div> */}

                            <div className="d-flex spaces gap-10">
                                {isTuyenHuyenOrTuyenXa && isChuaXacNhanOrXacNhanSaiThongTin && (
                                    <Button className={"button-primary"} onClick={() => setShouldOpenXacNhanThbDialog(true)}>
                                        {intl.formatMessage({ id: "CONFIRM" })}
                                    </Button>
                                )}
                                {
                                    dataRow?.truongHopBenh?.trangThaiPhanHoi !== TRANG_THAI_PHAN_HOI.DA_XN_DUNG && (
                                        <>
                                            {
                                                elementAbility?.update && (
                                                    <Button
                                                        className="button-primary"
                                                        onClick={() => {
                                                            setOpenDetailTHB(false)
                                                            setDataForm(dataRow)
                                                            setOpenTruongHopBenhForm(true)
                                                        }}
                                                    >
                                                        <OCTKTSVG path='/media/svg/icons/pencil-square.svg' svgClassName='spaces h-14 w-14 color-white' />
                                                        {intl.formatMessage({ id: "UPDATE" })}
                                                    </Button>
                                                )
                                            }
                                            {
                                                elementAbility?.delete && (
                                                    <Button
                                                        className="button-delete"
                                                        onClick={() => setOpenDeleteTruongHopBenh(true)}
                                                    >
                                                        <OCTKTSVG path='/media/svg/icons/trash.svg' svgClassName='spaces h-14 w-14 color-white' />
                                                        {intl.formatMessage({ id: "DELETE" })}
                                                    </Button>
                                                )
                                            }
                                        </>
                                    )
                                }
                                <DropdownButton
                                    title={intl.formatMessage({ id: "PATIENT_REPORT" })}
                                    dropdownItems={exportedFileList}
                                />
                            </div>
                        </div>
                        <div className="tt-tabs">
                            <ThongTinThb dataRow={dataRow} />
                        </div>
                    </div>
                </CustomDialog>
            }
        </div>
    )
}

export default DanhSachTruongHopBenh;
